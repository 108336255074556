export const THRESHOLD_MOBILE = 768;

import { useWindowWidth } from '@react-hook/window-size';

import useIsClient from './useIsClient';

const DEFAULT_IS_MOBILE = true;

const useIsMobile = () => {
  const width = useWindowWidth();
  const isClient = useIsClient();
  return isClient ? width < THRESHOLD_MOBILE : DEFAULT_IS_MOBILE;
};

export default useIsMobile;
