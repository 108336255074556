import React from 'react';

const CategorySVG: React.FC<SvgComponentProps> = ({ className }) => (
  <svg
    className={`fill-current ${className}`}
    height="19"
    viewBox="0 0 18 19"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.73438 0H0.703125C0.314758 0 0 0.332244 0 0.742186V8.16405C0 8.57399 0.314758 8.90623 0.703125 8.90623H7.73438C8.12274 8.90623 8.4375 8.57399 8.4375 8.16405V0.742186C8.4375 0.332244 8.12274 0 7.73438 0ZM7.03125 7.42186H1.40625V1.48437H7.03125V7.42186ZM17.566 10.1503C17.3033 10.0353 17.0008 10.0988 16.7997 10.311L9.76849 17.7329C9.56731 17.9452 9.50716 18.2644 9.61606 18.5417C9.72496 18.8192 9.98122 19 10.2656 19H17.2969C17.6852 19 18 18.6677 18 18.2578V10.8359C18 10.5357 17.8288 10.2651 17.566 10.1503ZM16.5938 17.5156H11.9632L16.5938 12.6277V17.5156ZM13.7812 8.90623C16.1075 8.90623 18 6.90856 18 4.45312C18 1.99767 16.1075 0 13.7812 0C11.455 0 9.5625 1.99767 9.5625 4.45312C9.5625 6.90856 11.455 8.90623 13.7812 8.90623ZM13.7812 1.48437C15.3321 1.48437 16.5938 2.8161 16.5938 4.45312C16.5938 6.09013 15.3321 7.42186 13.7812 7.42186C12.2304 7.42186 10.9688 6.09013 10.9688 4.45312C10.9688 2.8161 12.2304 1.48437 13.7812 1.48437ZM8.23151 11.3608L5.21315 14.5468L8.23151 17.7329C8.50616 18.0228 8.50616 18.4928 8.23151 18.7825C7.95699 19.0723 7.51176 19.0724 7.23724 18.7825L4.21875 15.5965L1.20026 18.7827C0.925735 19.0724 0.480515 19.0724 0.205994 18.7827C-0.0686646 18.4928 -0.0686646 18.0228 0.205994 17.733L3.22435 14.5468L0.205994 11.3608C-0.0686646 11.0709 -0.0686646 10.6009 0.205994 10.3112C0.480515 10.0213 0.925735 10.0213 1.20026 10.3112L4.21875 13.4972L7.23724 10.311C7.51176 10.0213 7.95699 10.0213 8.23151 10.311C8.50616 10.6009 8.50616 11.0709 8.23151 11.3608Z" />
  </svg>
);

export default CategorySVG;
