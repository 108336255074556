import React from 'react';
import { Media } from 'src/components/layout/media';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const DesktopComponent = ({ children, className }: Props) => {
  return (
    <Media className={className} greaterThanOrEqual="sm">
      {children}
    </Media>
  );
};

export default DesktopComponent;
