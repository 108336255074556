import 'src/css/rich-text.css';

import { Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

interface Props {
  document?: Document;
}

const options = {
  renderText: (text) =>
    text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const ContentfulRichText = ({ document }: Props) => {
  if (!document) {
    return null;
  }
  return (
    <div className="space-y-4 rich-text">
      {documentToReactComponents(document, options)}
    </div>
  );
};

export default ContentfulRichText;
