import translations from 'src/assets/translations';
import { LOCALE } from 'src/util/locale';

type TFunction = (key: TranslationKey) => string;

const locale = LOCALE;

const translation: Translation = translations[locale];

if (!translation) {
  throw new Error(`No translation found for locale "${locale}"`);
}

const tFunction: TFunction = (key) => translation.keys[key];

const useTranslation = () => tFunction;

export default useTranslation;
