/* eslint-disable react/no-unknown-property */
import React from 'react';

const ListsSVG: React.FC<SvgComponentProps> = ({ className }) => (
  <svg
    className={`fill-current ${className}`}
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.7403 6.00488H1.09162C0.790247 6.00488 0.545654 6.23818 0.545654 6.52564V14.2626C0.545654 15.4288 1.54066 16.3779 2.76327 16.3779H13.0686C14.2915 16.3779 15.2862 15.4291 15.2862 14.2626V6.52564C15.2863 6.23818 15.0417 6.00488 14.7403 6.00488ZM14.1944 14.2627C14.1944 14.8548 13.6894 15.3364 13.0686 15.3364H2.76333C2.14257 15.3364 1.63758 14.8548 1.63758 14.2627V7.04641H14.1944V14.2627Z" />
    <path d="M15.2865 3.27539H0.54596C0.244591 3.27539 0 3.5616 0 3.91426V6.45814C0 6.8108 0.244591 7.09701 0.54596 7.09701H15.2865C15.5881 7.09701 15.8324 6.81115 15.8324 6.45814V3.91426C15.8324 3.56153 15.5878 3.27539 15.2865 3.27539ZM14.7405 5.81928H1.09192V4.55306H14.7405V5.81928Z" />
    <path d="M9.82703 6.00488H6.00543C5.70406 6.00488 5.45947 6.23818 5.45947 6.52563V15.8571C5.45947 16.1446 5.70406 16.3779 6.00543 16.3779H9.82703C10.1286 16.3779 10.373 16.1448 10.373 15.8571V6.52557C10.373 6.23818 10.1284 6.00488 9.82703 6.00488ZM9.28107 15.3364H6.55133V7.04631H9.28107V15.3364Z" />
    <path d="M9.82703 3.27539H6.00543C5.70406 3.27539 5.45947 3.5616 5.45947 3.91426V6.45814C5.45947 6.8108 5.70406 7.09701 6.00543 7.09701H9.82703C10.1286 7.09701 10.373 6.81115 10.373 6.45814V3.91426C10.373 3.56153 10.1284 3.27539 9.82703 3.27539ZM9.28107 5.81928H6.55133V4.55306H9.28107V5.81928Z" />
    <path d="M9.24456 2.6955C9.13042 2.07927 8.76281 1.46605 8.20962 0.968748C6.99843 -0.119953 5.32298 -0.319321 4.39452 0.514836C3.46665 1.34905 3.6882 2.85595 4.89938 3.94489C5.60724 4.58107 6.4736 4.91351 7.25655 4.91351C7.81371 4.91351 8.32866 4.74533 8.71448 4.39875C9.17499 3.98442 9.36851 3.36371 9.24456 2.6955ZM7.92751 3.69065C7.45746 4.11386 6.43085 3.9061 5.68663 3.23704C4.94247 2.56799 4.71138 1.64524 5.18176 1.22208C5.65275 0.799173 6.67875 1.00688 7.42264 1.676C7.80752 2.02204 8.07148 2.45323 8.14683 2.85952C8.18441 3.06251 8.20592 3.44034 7.92751 3.69065Z" />
    <path d="M10.9547 0.515536C10.1194 -0.319731 8.6112 -0.120109 7.52117 0.969888C6.43138 2.05989 6.23181 3.56794 7.06711 4.40327C7.39988 4.73579 7.86508 4.91351 8.38115 4.91351C8.50879 4.91351 8.63976 4.90244 8.77236 4.8803C9.38897 4.77759 10.0028 4.44676 10.5006 3.94892C10.9984 3.45107 11.3293 2.83732 11.432 2.22049C11.5436 1.55182 11.3694 0.930266 10.9547 0.515536ZM10.4441 2.05601C10.3763 2.46246 10.139 2.89437 9.79234 3.24068C9.44595 3.58705 9.01433 3.8246 8.60764 3.89242C8.40498 3.92678 8.02647 3.94583 7.77591 3.69503C7.35227 3.27141 7.56025 2.34783 8.22997 1.67837C8.57636 1.33199 9.00798 1.09444 9.41468 1.02663C9.47808 1.0161 9.55835 1.00697 9.64667 1.00697C9.84134 1.00697 10.0742 1.05155 10.2464 1.22401C10.4973 1.47457 10.4779 1.85306 10.4441 2.05601Z" />
    <path d="M8.18921 8.73486H20.2V19.1078H8.18921V8.73486Z" fill="white" />
    <path
      clip-rule="evenodd"
      d="M19.3297 9.62909H9.05956V18.2136H19.3297V9.62909ZM8.18921 8.73486V19.1078H20.2V8.73486H8.18921Z"
      fill-rule="evenodd"
    />
    <path d="M17.7316 10.9189H10.6581C10.5008 10.9189 10.3733 11.1632 10.3733 11.4647C10.3733 11.7663 10.5008 12.0108 10.6581 12.0108H17.7315C17.8888 12.0108 18.0165 11.7663 18.0165 11.4647C18.0165 11.1632 17.8888 10.9189 17.7316 10.9189Z" />
    <path d="M14.4377 13.103H10.6764C10.509 13.103 10.3733 13.3473 10.3733 13.6488C10.3733 13.9504 10.509 14.1949 10.6764 14.1949H14.4377C14.6051 14.1949 14.7409 13.9507 14.7409 13.6491C14.7409 13.3476 14.6051 13.103 14.4377 13.103Z" />
  </svg>
);

export default ListsSVG;
