import cx from 'classnames';
import React, { useCallback, useRef } from 'react';
import { t } from 'src/assets/translations';
import ExpandableSection from 'src/components/widget/expandable-section';
import { DropdownItem, MenuItem } from 'typings/custom';
import { ICategoryItem } from 'typings/generated/contentful';

type PopperProps = {
  visible: boolean;
  menuItem: MenuItem;
  onClose: Function;
};

const NavbarItemPopperMobile = ({
  visible,
  menuItem,
  onClose,
}: PopperProps) => {
  const { items = [], prefix, icon: Icon, label, color } = menuItem;
  const isMega = items.some((item) => item.fields?.subcategories);
  const popperRef = useRef<HTMLDivElement>(null);

  const handleBackdropClicked = useCallback(
    (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        event.preventDefault();
        onClose();
      }
    },
    [popperRef.current, onClose, visible]
  );

  const renderHeader = useCallback(
    () => (
      <div
        className="flex items-center px-8 py-3 space-x-4 text-3xl font-normal text-white font-display"
        key={label}
        style={{ backgroundColor: color }}
      >
        <Icon className="w-6 h-6" />
        <div className="h-6 leading-none">{label}</div>
      </div>
    ),
    [label, color]
  );

  const renderMegaItem = useCallback(
    (item: ICategoryItem, index: number, list: ICategoryItem[]) => {
      const { subcategories, slug, name } = item.fields;
      if (!subcategories || subcategories.length === 0) {
        return (
          <div
            className={cx(
              'px-8 py-2 text-xl border-x-gray-light font-display',
              { 'border-b': index < list.length - 1 }
            )}
          >
            <a aria-label={name} href={`${prefix}/${slug}`} key={name}>
              {name}
            </a>
          </div>
        );
      }

      return (
        <div className="px-8 text-xl bg-white font-display" key={name}>
          <ExpandableSection
            chevronClassName="text-x-dark-blue"
            className={cx('py-2 border-x-gray-light font-bold', {
              'border-b': index < list.length - 1,
            })}
            title={name}
          >
            {subcategories && subcategories.length && (
              <div
                className={cx('py-2 border-x-gray-light font-normal', {
                  'border-b': subcategories.length > 1,
                })}
              >
                <a aria-label={name} href={`${prefix}/${slug}`} key={name}>
                  {t('allProducts')}
                </a>
              </div>
            )}
            {subcategories?.map(({ fields: { name, slug } }) => (
              <a aria-label={name} href={`${prefix}/${slug}`} key={name}>
                <div
                  className={cx(
                    'py-2 bg-white hover:underline border-x-gray-light font-normal',
                    { 'border-b': index < list.length - 1 }
                  )}
                  key={name}
                >
                  {name}
                </div>
              </a>
            ))}
          </ExpandableSection>
        </div>
      );
    },
    []
  );

  const renderRegularItem = useCallback(
    (item: DropdownItem, index: number, list: MenuItem[]) => {
      const { name, slug } = item.fields || item;
      return (
        <a aria-label={name} href={`${prefix}/${slug}`} key={name}>
          <div
            className={cx(
              'px-8 py-2 text-xl bg-white font-display hover:underline border-x-gray-light',
              { 'border-b': index < list.length - 1 }
            )}
          >
            {name}
          </div>
        </a>
      );
    },
    []
  );

  let popperContent: any[] = [];

  if (isMega) {
    popperContent = [
      renderHeader(),
      ...(items as ICategoryItem[]).map(renderMegaItem),
    ];
  } else if (items.length) {
    popperContent = [renderHeader(), ...items.map(renderRegularItem)];
  }

  if (!popperContent.length) {
    return null;
  }

  return (
    <>
      {/* backdrop */}
      <div
        className={cx(
          'fixed z-20 w-screen bottom-0 top-0 bg-black bg-opacity-50 transition-opacity duration-200 opacity-0',
          {
            'pointer-events-none': !visible,
            'opacity-100': visible,
          }
        )}
        onClick={handleBackdropClicked}
        style={{ margin: 0 }}
      >
        <div
          className="z-50 flex-col flex-wrap w-3/4 h-full overflow-scroll font-bold transition-transform duration-200 bg-white rounded-none shadow-md pointer-events-auto"
          ref={popperRef}
          style={{ transform: `translateX(-${visible ? 0 : 75}vw)` }}
        >
          {popperContent}
        </div>
      </div>
      <span
        className={cx(
          'fixed z-50 top-0 right-0 px-4 py-2 text-white font-bold text-3xl pointer-events-none',
          {
            hidden: !visible,
          }
        )}
      >
        X
      </span>
    </>
  );
};

export default NavbarItemPopperMobile;
