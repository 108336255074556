import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import CookiesIcon from 'src/assets/img/ic_cookies.svg';
import { COOKIE_KEY_COOKIE_CONSENT_ACCEPTED } from 'src/constants';
import { useStaticContext } from 'src/hooks/useStaticContext';
import { ILegalTexts, IMiscStaticContent } from 'typings/generated/contentful';
import Cookies from 'universal-cookie';

import ContentfulRichText from '../misc/contentful-rich-text';

function CookieNotice(): ReactElement | null {
  const { miscStaticContent, legalTexts } = useStaticContext();
  const cookieNotice: IMiscStaticContent = miscStaticContent?.find(
    (content) => content.sys.id === '1c60NZwt4O1rRjKemg8hlT'
  );
  const privacyLegalText: ILegalTexts = legalTexts?.find(
    (legalText) => legalText.sys.id === '2hjDJGkJdxMXQdBBmzP2nV'
  );
  const [showCookieNotice, setShowCookieNotice] = useState(false);
  const cookies = useRef(new Cookies()).current;

  const acceptCookies = useCallback(() => {
    setShowCookieNotice(false);
    cookies.set(COOKIE_KEY_COOKIE_CONSENT_ACCEPTED, true, {
      path: '/',
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365), // expires in 1 year
    });
  }, []);

  useEffect(() => {
    const accepted = cookies.get(COOKIE_KEY_COOKIE_CONSENT_ACCEPTED);
    setShowCookieNotice(!accepted);
  }, []);

  if (!showCookieNotice) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 flex flex-col p-4 m-4 space-y-2 text-white rounded-lg sm:left-auto sm:max-w-sm bg-x-orange">
      <div className="flex items-center justify-center space-x-2">
        <CookiesIcon className="w-6 h-6" />
        <span className="text-xl font-bold">{cookieNotice.fields.title}</span>
      </div>
      <div className="text-sm text-center">
        <ContentfulRichText document={cookieNotice.fields.description} />
      </div>
      <div className="flex flex-col items-stretch justify-center pt-2 space-y-2 text-sm sm:space-x-2 sm:space-y-0 sm:items-center sm:flex-row">
        <button
          className="px-4 py-3 font-bold bg-white rounded-lg text-x-orange"
          onClick={() => acceptCookies()}
        >
          {cookieNotice.fields.button1}
        </button>
        <button className="px-4 py-3 font-bold bg-white bg-opacity-25 rounded-lg">
          <a
            aria-label={privacyLegalText.fields.name}
            className="text-center"
            href={`/${privacyLegalText.fields.slug}`}
          >
            {cookieNotice.fields.button2}
          </a>
        </button>
      </div>
    </div>
  );
}

export default CookieNotice;
