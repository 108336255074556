import React from 'react';

const CategorySVG: React.FC<SvgComponentProps> = ({ className }) => (
  <svg
    className={`fill-current ${className}`}
    fill="none"
    height="24"
    viewBox="0 0 27 24"
    width="27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.6678 10.1541H1.33217C0.596415 10.1541 0 10.9807 0 12.0002C0 13.0198 0.596501 13.8463 1.33217 13.8463H25.6678C26.4036 13.8463 27 13.0196 27 12.0002C27 10.9806 26.4036 10.1541 25.6678 10.1541Z" />
    <path d="M21.0341 0H5.96585C5.43241 0 5 0.895504 5 1.99994C5 3.1045 5.43248 4 5.96585 4H21.0341C21.5675 4 22 3.1045 22 1.99994C22 0.895504 21.5675 0 21.0341 0Z" />
    <path d="M21.0341 20H5.96585C5.43241 20 5 20.8955 5 22C5 23.1046 5.43248 24 5.96585 24H21.0341C21.5675 24 22 23.1045 22 22C22 20.8954 21.5675 20 21.0341 20Z" />
  </svg>
);

export default CategorySVG;
