/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import 'src/css/main.css';

import React from 'react';
import { FunctionComponent } from 'react';
import { ReactElement } from 'react';
import { ReactNode } from 'react';
import Navbar from 'src/components/navigation/navbar';
import { StaticContext } from 'src/hooks/useStaticContext';
import { StaticContextType } from 'typings/custom';

import CookieNotice from '../widget/cookie-notice';
import Footer from './footer';

interface LayoutProps {
  children: ReactNode;
  context: StaticContextType;
}

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  context,
}: LayoutProps): ReactElement => {
  return (
    <StaticContext.Provider value={context}>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="py-4 overflow-x-hidden md:py-8">{children}</main>
        {/* spacer */}
        <div className="flex flex-1" />
        <CookieNotice />
        <Footer />
      </div>
    </StaticContext.Provider>
  );
};

export default Layout;
