const deDE: Translation = {
  locale: 'de-DE',
  keys: {
    allProducts: 'Alle Produkte',
    random: 'Zufällig',
    bestProducts: 'Beliebteste',
    category: 'Kategorie',
    guides: 'Guides',
    products: 'Produkte',
    presentGuides: 'Geschenk-Guides',
    more: 'Mehr',
    moreInfo: 'Mehr Info',
    toTheProduct: 'Zum Produkt',
    newestProducts: 'Neueste',
    nfsw: 'NFSW',
    occasion: 'Anlass',
    productsUnder20: 'Produkte unter €20',
    productsUnder100: 'Produkte unter €100',
    recipient: 'Für wen',
    recipientSlug: 'Empfaenger',
    home: 'Startseite',
    loadingMore: 'Mehr laden...',
    shareThisProductWithFriends: 'Teile das Product mit Freunden!',
    shareWithSocialMedia: 'AUF SOCIAL MEDIA TEILEN',
    contact: 'Kontakt',
    faq: 'FAQ',
    imprint: 'Impressum',
    privacyPolicy: 'Datenschutzerklärung',
    doYouLikeThisProduct: 'Gefällt dir dieses Produkt?',
    signUpNow: 'JETZT ANMELDEN',
    yourEmail: 'Deine E-Mail',
    subscribeToOurNewsletter:
      'Melde dich für unseren Newsletter an um jede Woche die 🔥 und verrücktesten Geschenkideen direkt in deine Inbox zu bekommen!',
    similarProducts: 'Ähnliche Produkte',
    nfswOff: 'AUS',
    nfswOn: 'AN',
    morePresentGuides: 'Weitere TOP-Listen',
    shareWithWhatsApp: 'Auf Whatsapp teilen',
    price: 'PREIS',
    yourName: 'Dein name:',
    yourMessage: 'DEINE Nachricht:',
    submit: 'ABschicken',
    published: 'Veröffentlicht',
    updated: 'Aktualisiert',
    customerReviews: 'Kundenbewertungen',
  },
};

export default deDE;
