/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FunctionComponent } from 'react';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticContext } from 'src/hooks/useStaticContext';
import { LOCALE } from 'src/util/locale';
import { ISeoInformation } from 'typings/generated/contentful';

type Meta = {
  name: string;
  content: string;
};

type SeoProps = {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title?: string;
  image?: string;
};

type QueryType = {
  contentfulSeoInformation: ISeoInformation;
  site: {
    buildTime: string;
    siteMetadata: {
      title: string;
      description: string;
      author: string;
    };
  };
};

const query = graphql`
  {
    site {
      buildTime
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const SEO: FunctionComponent<SeoProps> = ({
  description,
  meta = [],
  title,
  image,
}: SeoProps): ReactElement => {
  const { site } = useStaticQuery<QueryType>(query);
  const { seoInformation } = useStaticContext();
  const siteName = site.siteMetadata.title;

  let metaTitle = title || seoInformation.fields.title;
  metaTitle = `${metaTitle} | ${siteName}`;

  const metaDescription =
    description === undefined ? seoInformation.fields.description : description;
  const seoImage = image || '/img/seo-default.png';

  return (
    <Helmet
      htmlAttributes={{
        lang: LOCALE,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: seoImage,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: seoImage,
        },
        {
          name: 'built-at',
          content: site.buildTime,
        },
      ].concat(meta)}
      title={metaTitle}
    />
  );
};

export default SEO;
