import cx from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  loose?: boolean;
  className?: string;
}

const Container = ({ children, loose, className }: Props) => {
  return (
    <div
      className={cx(
        'w-screen max-w-screen-lg md:px-2',
        { 'px-2': loose },
        { 'px-6': !loose },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;
