import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import NavbarItemPopperMobile from 'src/components/navigation/navbar-item-popper.mobile';
import { MenuItem } from 'typings/custom';

type Props = {
  menuItem: MenuItem;
};

const NavbarItemMobile = ({ menuItem }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { label, prefix, items = [], icon: Icon } = menuItem;

  const className =
    'w-full h-20 overflow-hidden border-2 rounded select-none text-x-orange border-x-orange ';

  const content = (
    <div
      className={cx(
        'flex flex-col justify-center items-center w-full h-full border-x-orange title',
        {
          'bg-x-orange': expanded,
          'text-white': expanded,
        }
      )}
    >
      <div className="p-2">
        <Icon className="w-6 h-6" />
      </div>
      {label}
    </div>
  );

  const handleAnchorClicked = useCallback(() => {
    if (items.length) {
      setExpanded((v) => !v);
    } else {
      window.location.href = prefix;
    }
  }, [items]);

  if (items.length) {
    return (
      <>
        <div className="flex-1">
          <button
            className={className}
            onClick={handleAnchorClicked}
            role="button"
          >
            {content}
          </button>
        </div>
        <NavbarItemPopperMobile
          menuItem={menuItem}
          onClose={() => setExpanded((v) => !v)}
          visible={expanded}
        />
      </>
    );
  }

  return (
    <div className="flex-1">
      <a aria-label={label} href={prefix}>
        <div className={className}>{content}</div>
      </a>
    </div>
  );
};

export default NavbarItemMobile;
