import { Link } from 'gatsby';
import orderBy from 'lodash/orderBy';
import React from 'react';
import FooterLogo from 'src/assets/img/logo_alt.svg';
import Container from 'src/components/widget/container';
import { useStaticContext } from 'src/hooks/useStaticContext';

const Footer = () => {
  const { contactUsPage, legalTexts } = useStaticContext();
  return (
    <footer className="flex justify-center py-8 text-white md:py-4 bg-x-black">
      <Container>
        <div className="flex flex-col w-full px-8 space-y-4 md:items-center md:flex-row md:px-0 md:space-x-8 md:space-y-0">
          <Link aria-label="Home" className="-mx-4" to="/">
            <FooterLogo className="h-12" />
          </Link>
          <div className="h-4 md:h-0 md:flex-1" />
          {orderBy(legalTexts, 'name', 'desc').map(
            ({ fields: { name, slug, id } }) => (
              <Link aria-label={name} key={id} to={`/${slug}`}>
                {name}
              </Link>
            )
          )}
          <Link aria-label="Contact" to={`/${contactUsPage.fields.slug}`}>
            {contactUsPage.fields.title}
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
