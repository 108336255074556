import cx from 'classnames';
import React, { useRef } from 'react';
import ChevronDown from 'src/assets/img/ic_chevron_down.svg';
import NavbarItemPopper from 'src/components/navigation/navbar-item-popper';
import { MenuItem } from 'typings/custom';

type Props = {
  leftIcon?: string;
  megaMenu?: boolean;
  expanded: boolean;
  onExpand: Function;
  onClose: Function;
  menuItem: MenuItem;
};

const NavbarItemDropdown = ({
  onExpand,
  onClose,
  expanded,
  menuItem,
}: Props) => {
  const { items, prefix, label } = menuItem;
  const isDropdown = items && items.length > 0;
  const labelRef = useRef<HTMLButtonElement>(null);

  const className = 'h-12 border-none bg-x-orange navbar-btn';

  const content = (
    <div
      className={cx(
        'flex flex-row items-center justify-center h-full w-full px-2 pointer-events-none transition-colors duration-200 text-white',
        {
          'bg-x-red': expanded,
        }
      )}
    >
      <span className="mx-2 text-2xl title">{label}</span>
      {isDropdown && (
        <div
          className={cx('inline transform transition-transform duration-200', {
            'rotate-180': expanded,
          })}
        >
          <ChevronDown className="w-3 h-3 fill-current" />
        </div>
      )}
    </div>
  );

  if (isDropdown) {
    return (
      <>
        <button
          className={className}
          onClick={() => onExpand()}
          ref={labelRef}
          role="button"
        >
          {content}
        </button>
        <NavbarItemPopper
          labelRef={labelRef.current}
          menuItem={menuItem}
          onClickOutside={onClose}
          visible={expanded}
        />
      </>
    );
  }

  return (
    <a aria-label={label} href={prefix}>
      <div className={className}>{content}</div>
    </a>
  );
};

export default NavbarItemDropdown;
