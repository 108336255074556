import cx from 'classnames';
import React, { useState } from 'react';
import ChevronDown from 'src/assets/img/ic_chevron_down.svg';

type Props = {
  title: string | undefined;
  className?: string;
  chevronClassName?: string;
  children: React.ReactNode;
};

const ExpandableSection = ({
  title,
  className,
  chevronClassName,
  children,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="flex-col">
      <button
        className={cx('flex items-center w-full', className)}
        onClick={() => setExpanded((v) => !v)}
      >
        <div className="flex-1 text-left">{title}</div>
        <div
          className={cx('flex items-center text-x-orange', chevronClassName)}
        >
          <div
            className={cx(
              'transform transition-transform duration-200 fill-current',
              {
                '-rotate-90': !expanded,
                'rotate-0': expanded,
              }
            )}
          >
            <ChevronDown className="w-4 h-4 fill-current" />
          </div>
        </div>
      </button>
      <div
        className={cx(
          'overflow-hidden transform origin-top transition-all duration-200',
          {
            'scale-y-0': !expanded,
            'scale-y-100': expanded,
          }
        )}
        style={{ height: expanded ? 'auto' : 0 }}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableSection;
