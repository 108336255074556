import deDE from './de-DE';

const translations = {
  'de-DE': deDE,
};

export const t = (key: TranslationKey) => {
  const locale = process.env.GATSBY_LOCALE as string;
  if (!locale) {
    throw new Error('Locale not set');
  }
  return translations[locale].keys[key];
};

export default translations;
