import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from 'src/assets/img/logo.svg';
import NavbarItemDropdown from 'src/components/navigation/navbar-item';
import NavbarItemMobile from 'src/components/navigation/navbar-item.mobile';
import CategorySVG from 'src/components/svg/category';
import ListsSVG from 'src/components/svg/lists';
import MoreSVG from 'src/components/svg/more';
import OccasionSVG from 'src/components/svg/occasion';
import RecipientSVG from 'src/components/svg/recipient';
import Container from 'src/components/widget/container';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import useIsMobile from 'src/hooks/useIsMobile';
import { useStaticContext } from 'src/hooks/useStaticContext';
import useTranslation from 'src/hooks/useTranslation';
import { MenuItem } from 'typings/custom';
import {
  ICategoryWrapper,
  IContactUsPage,
  ICustomGiftlistWrapper,
  IFaqWrapper,
  IOccasionWrapper,
  IRecipientWrapper,
} from 'typings/generated/contentful';

const MOBILE_NAVBAR_HEIGHT = 208; // 13rem

type NavbarQuery = {
  contentfulRecipientWrapper: IRecipientWrapper;
  contentfulCategoryWrapper: ICategoryWrapper;
  contentfulOccasionWrapper: IOccasionWrapper;
  contentfulFaqWrapper: IFaqWrapper;
  contentfulCustomGiftlistWrapper: ICustomGiftlistWrapper;
  contentfulContactUsPage: IContactUsPage;
};

const Navbar = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const context = useStaticContext();
  const t = useTranslation();
  const [scrollDirection, setScrollDirection] = useState<
    'initial' | 'up' | 'down'
  >('initial');

  const isMobile = useIsMobile();
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!isMobile) {
        return;
      }
      const scrollUp = currPos.y > prevPos.y;
      if (currPos.y >= 0) {
        // ignore overscrolling
        return;
      }
      if (-currPos.y > 2 * MOBILE_NAVBAR_HEIGHT) {
        setScrollDirection(scrollUp ? 'up' : 'down');
      } else {
        setScrollDirection('initial');
      }
    },
    [isMobile]
  );

  const menuItems: MenuItem[] = [
    {
      label: context.recipientWrapper.fields.title,
      prefix: `/${context.recipientWrapper.fields.slug}`,
      key: 'recipient',
      items: context.recipientWrapper.fields.recipientItems,
      icon: RecipientSVG,
      color: '#F98274',
    },
    {
      label: context.occasionWrapper.fields.title,
      prefix: `/${context.occasionWrapper.fields.slug}`,
      key: 'occasion',
      items: context.occasionWrapper.fields.occasionItems,
      icon: OccasionSVG,
      color: '#53339C',
    },
    {
      label: context.categoryWrapper.fields.title,
      prefix: `/${context.categoryWrapper.fields.slug}`,
      key: 'category',
      items: context.categoryWrapper.fields.categoryItems,
      icon: CategorySVG,
      color: '#FFD152',
    },
    {
      label: t('more'),
      prefix: '',
      key: 'more',
      icon: MoreSVG,
      color: '#4CE0B3',
      items: [
        {
          name: context.customGiftlistWrapper.fields.title,
          slug: context.customGiftlistWrapper.fields.slug,
        },
        {
          name: context.faqWrapper.fields.title,
          slug: context.faqWrapper.fields.slug,
        },
        {
          name: context.contactUsPage.fields.title,
          slug: context.contactUsPage.fields.slug,
        },
      ],
    },
  ];

  const desktopMenuItems = [
    ...menuItems.slice(0, -1),
    {
      label: context.customGiftlistWrapper.fields.title,
      prefix: `/${context.customGiftlistWrapper.fields.slug}`,
      key: 'lists',
      items: [],
      icon: ListsSVG,
    },
    {
      label: t('more'),
      prefix: '',
      key: 'more',
      icon: () => null,
      items: [
        {
          name: context.faqWrapper.fields.title,
          slug: context.faqWrapper.fields.slug,
        },
        {
          name: context.contactUsPage.fields.title,
          slug: context.contactUsPage.fields.slug,
        },
      ],
    },
  ];

  const onItemPressed = (key) => {
    if (selectedMenuItem === key) {
      setSelectedMenuItem(null);
    } else {
      setSelectedMenuItem(key);
    }
  };

  let headerStyle;
  let spacerStyle;
  if (isMobile) {
    if (scrollDirection === 'initial') {
      headerStyle = {
        position: 'relative',
      };
    } else {
      headerStyle = {
        position: 'fixed',
        top:
          scrollDirection === 'up'
            ? -(MOBILE_NAVBAR_HEIGHT / 2)
            : -MOBILE_NAVBAR_HEIGHT,
      };
      spacerStyle = {
        height: MOBILE_NAVBAR_HEIGHT,
      };
    }
  }

  return (
    <>
      {isMobile && <div style={spacerStyle} />}
      <header
        className="z-40 transition-all duration-500 origin-top bg-white md:top-0 md:sticky md:transition-none"
        style={headerStyle}
      >
        <div className="flex flex-col items-center justify-center flex-1 md:py-4 md:border-b-4 md:border-x-orange md:flex-row">
          <Container>
            <div className="flex flex-col items-center w-full max-w-screen-lg md:flex-row">
              <div className="flex justify-center py-4 md:-my-2 md:py-0">
                <Link aria-label="Home" to="/">
                  <Logo className="h-20" />
                </Link>
              </div>
              <MobileComponent className="w-full">
                <div className="flex justify-center flex-1 mb-4 space-x-2 md:py-4 md:pt-0">
                  {menuItems.map((menuItem) => (
                    <NavbarItemMobile key={menuItem.key} menuItem={menuItem} />
                  ))}
                </div>
              </MobileComponent>
              <DesktopComponent className="w-full">
                <div className="flex justify-end flex-1 max-w-6xl pt-4 space-x-2 md:pt-0">
                  {desktopMenuItems.map((menuItem) => (
                    <NavbarItemDropdown
                      expanded={selectedMenuItem === menuItem.key}
                      key={menuItem.key}
                      menuItem={menuItem}
                      onClose={() => onItemPressed(null)}
                      onExpand={() => onItemPressed(menuItem.key)}
                    />
                  ))}
                </div>
              </DesktopComponent>
            </div>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Navbar;
