import React from 'react';

const RecipientSVG: React.FC<SvgComponentProps> = ({ className }) => (
  <svg
    className={`fill-current ${className}`}
    height="27"
    viewBox="0 0 27 27"
    width="27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.0849 1.52001C20.2127 0.539809 19.0038 0 17.6806 0C16.2038 0 14.9236 0.698317 13.9785 2.01939C13.9076 2.11844 13.8413 2.21749 13.7792 2.31554C13.7171 2.21744 13.6508 2.11844 13.5799 2.01939C12.6347 0.698317 11.3546 0 9.87771 0C8.55459 0 7.34556 0.539809 6.47342 1.51996C5.6458 2.45014 5.18994 3.69367 5.18994 5.02146C5.18994 6.46439 5.74989 7.79585 6.95194 9.21178C8.00078 10.4471 9.50522 11.7169 11.2473 13.1873C11.891 13.7307 12.5567 14.2925 13.2641 14.9056C13.4119 15.0336 13.5955 15.0977 13.7791 15.0977C13.9627 15.0977 14.1463 15.0336 14.2941 14.9056C15.0014 14.2926 15.667 13.7308 16.3107 13.1875C17.4295 12.2432 18.3957 11.4277 19.2342 10.6367C20.8621 9.10078 22.3684 7.3575 22.3684 5.02151C22.3684 3.69367 21.9126 2.45019 21.0849 1.52001ZM18.155 9.49272C17.3482 10.2539 16.3974 11.0564 15.2964 11.9857C14.8066 12.399 14.3043 12.823 13.7792 13.2733C13.2539 12.823 12.7515 12.3989 12.2617 11.9855C8.83254 9.09118 6.76265 7.34418 6.76265 5.02151C6.76254 3.05539 8.10171 1.57276 9.87766 1.57276C10.8265 1.57276 11.6334 2.01939 12.2759 2.90024C12.8033 3.62309 13.0198 4.37248 13.0213 4.37751C13.1157 4.71818 13.4257 4.95403 13.7791 4.95403C14.1326 4.95403 14.4426 4.71818 14.5369 4.37751C14.5447 4.34946 15.3408 1.57276 17.6806 1.57276C19.4565 1.57276 20.7957 3.05544 20.7957 5.02151C20.7957 6.44609 20.0553 7.69969 18.155 9.49272Z" />
    <path d="M24.4813 15.8374C23.829 15.8374 23.1997 16.1113 22.7549 16.5888L20.4438 19.0699C20.1451 18.1095 19.248 17.4102 18.1906 17.4102H14.558C14.0495 17.4102 13.559 17.2251 13.1772 16.8891C11.1237 15.082 8.03715 15.0661 5.96417 16.8392C5.53667 16.2339 4.83227 15.8375 4.03653 15.8375H0.786354C0.352095 15.8375 0 16.1895 0 16.6239V26.0599C0 26.4942 0.352043 26.8463 0.786354 26.8463H4.03653C4.74272 26.8463 5.37696 26.5339 5.80965 26.0406L6.27531 26.1715C7.86816 26.6192 9.51469 26.8462 11.1693 26.8462H18.1906C19.9139 26.8462 21.5601 26.0231 22.5941 24.6444L26.3693 19.6109C26.3693 19.6108 26.3694 19.6107 26.3695 19.6106C26.6775 19.1998 26.8403 18.7108 26.8403 18.1964C26.8403 16.8957 25.7821 15.8374 24.4813 15.8374ZM4.82289 24.4871C4.82289 24.9207 4.47016 25.2735 4.03653 25.2735H1.57266V17.4101H4.03653C4.47011 17.4101 4.82289 17.7628 4.82289 18.1965V24.4871ZM25.1112 18.6672C25.1112 18.6672 25.1112 18.6672 25.1111 18.6673L21.336 23.7009C20.5974 24.6857 19.4215 25.2735 18.1906 25.2735H11.1694C9.65873 25.2735 8.15534 25.0663 6.70103 24.6575L6.3935 24.571C6.3945 24.5431 6.39565 24.5153 6.39565 24.4872V18.5519L6.94364 18.0697C8.42438 16.7667 10.6576 16.7666 12.1384 18.0697C12.8076 18.6585 13.6668 18.9827 14.5581 18.9827H18.1908C18.6243 18.9827 18.9771 19.3355 18.9771 19.7691C18.9771 20.2026 18.6244 20.5554 18.1908 20.5554H12.6021C12.1678 20.5554 11.8157 20.9075 11.8157 21.3418C11.8157 21.7761 12.1678 22.1281 12.6021 22.1281H18.7177C19.37 22.1281 19.9992 21.8544 20.4438 21.377L23.9057 17.6607C24.0562 17.4991 24.2607 17.4101 24.4813 17.4101C24.9149 17.4101 25.2677 17.7628 25.2677 18.1965C25.2677 18.3679 25.2136 18.5307 25.1112 18.6672Z" />
  </svg>
);

export default RecipientSVG;
