import { useWindowSize } from '@react-hook/window-size';
import cx from 'classnames';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { DESKTOP_CONTENT_WIDTH } from 'src/components/../constants';
import { DropdownItem, MenuItem } from 'typings/custom';
import { ICategoryItem } from 'typings/generated/contentful';

const NAVBAR_EXTRA_PADDING_PX = 8;

type PopperProps = {
  visible: boolean;
  labelRef: HTMLButtonElement | null;
  menuItem: MenuItem;
  onClickOutside: Function;
};

const NavbarItemPopper = ({
  visible,
  labelRef,
  menuItem: { prefix, items = [] },
  onClickOutside,
}: PopperProps) => {
  const [dropdownStyle, setDropdownStyle] = useState<CSSProperties>({});
  const [width, height] = useWindowSize();
  const isMega = items.some((item) => item.fields?.subcategories);
  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (labelRef !== null) {
      let style: CSSProperties = {
        display: visible ? 'flex' : 'none',
        minWidth: labelRef.offsetWidth,
        top:
          labelRef.offsetTop + labelRef.clientHeight + NAVBAR_EXTRA_PADDING_PX,
        marginLeft: 0,
      };

      if (isMega) {
        style = {
          ...style,
          width: DESKTOP_CONTENT_WIDTH,
          maxWidth: '100vw',
          maxHeight: '32rem',
          left: (width - Math.min(width, DESKTOP_CONTENT_WIDTH)) / 2,
        };
      } else {
        style = {
          ...style,
          right: width - labelRef.offsetLeft - labelRef.clientWidth,
        };
      }

      setDropdownStyle(style);
    }
  }, [labelRef, visible, width, height]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        visible &&
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        !event.target.classList.contains('navbar-btn')
      ) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [visible, popperRef.current]);

  let popperContent: DropdownItem[];
  if (isMega) {
    popperContent = (items as ICategoryItem[]).map(
      ({ fields: { name, slug, subcategories } }) => (
        <div className="px-4 font-bold text-gray-700 bg-white" key={name}>
          <div
            className={cx('pt-4', {
              'pb-4': subcategories && subcategories.length > 0,
            })}
          >
            <a aria-label={name} href={`${prefix}/${slug}`} key={name}>
              {name}
            </a>
          </div>
          {subcategories?.map(({ fields: { name, slug } }) => (
            <a aria-label={name} href={`${prefix}/${slug}`} key={name}>
              <div
                className="py-0 font-normal text-gray-700 bg-white hover:underline"
                key={name}
              >
                {name}
              </div>
            </a>
          ))}
        </div>
      )
    );
  } else {
    popperContent = items.map((item) => {
      const { name, slug, link } = item.fields || item;
      return (
        <a aria-label={name} href={link || `${prefix}/${slug}`} key={name}>
          <div className="px-6 py-1 text-gray-700 bg-white hover:underline">
            {name}
          </div>
        </a>
      );
    });
  }

  return (
    <div
      className={cx(
        'absolute z-50 flex-col flex-wrap hidden p-4 mt-5 bg-white rounded-t-none rounded-b-lg shadow-md',
        {
          'px-0': !isMega,
        }
      )}
      ref={popperRef}
      style={dropdownStyle}
    >
      {popperContent}
    </div>
  );
};

export default NavbarItemPopper;
