export const DESKTOP_CONTENT_WIDTH = 1024; // screen-l breakpoint

export const COLOR_YELLOW = '#F3B65D';
export const COLOR_ORANGE = '#DA7534';
export const COLOR_RED = '#D75D33';
export const COLOR_GREEN = '#71E63A';
export const COLOR_GREEN_NEON = '#6FBF49';
export const COLOR_BLACK = '#44403E';
export const COLOR_DARK_BLUE = '#45556A';
export const COLOR_WHATSAPP_GREEN = '#4CAF50';
export const COLOR_GRAY = '#D0D0D0';
export const COLOR_GRAY_LIGHT = '#F7F7F7';
export const COLOR_GRAY_DARK = '#989DA2';

export const COOKIE_KEY_NEWSLETTER_FORM_SHOWN =
  'krassgeil/marketing/newletter_form_shown';

export const COOKIE_KEY_COOKIE_CONSENT_ACCEPTED =
  'krassgeil/marketing/cookie_consent_accepted';
